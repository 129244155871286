.ReactTable {
    border: none;
}

.ReactTable .rt-table {
    /* this is done to make dropdown menu visible */
    overflow: visible;

}

.ReactTable .rt-table .-header {
    height: 60px;
    background-color: #fdfdfe;
    font-family: Roboto-Bold, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #999999;
    box-shadow: none;
}

.ReactTable .rt-thead .rt-tr {
    align-items: center;
    text-align: left;
    padding: 0 25px;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
    border-right: none;
    
}
.ReactTable .rt-tbody .rt-td {
    border-right: none;
    height: 40px !important;
}

.ReactTable .rt-tbody {
    background-color: #fcfcfd;
    font-family: Roboto-Regular, sans-serif;

    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #394a58;

    /* this is done to make dropdown menu visible */
    overflow: visible;
}

.ReactTable .rt-tbody .rt-tr-group {
    justify-content: stretch;
    padding: 0 25px;
}

.ReactTable .rt-tr {
    align-items: stretch;
    min-height: 60px;
}

.ReactTable .rt-thead .rt-th {
    outline: none;
}

