.selectorList {
    position: relative;
    width: 200px;
    z-index: 999;
    border: 1px solid #999;
    padding: 5px;
    border-radius: 5px;
    font-family: Roboto-Medium;
    /*height: 20px;*/
}
.selectorList .inputBlock {
    width: 100%;
    max-width: calc(100% - 15px);
}
.selectorList__list {
    position: absolute;
    width: 100%;
    max-height: 200px;
    top: 40px;
    left: 0;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    overflow-y: auto;
    border: 2px solid #88a4bd;
}

.closeList {
    position: absolute;
    font-size: 10px;
    right: 0;
    top: 39px;
    z-index: 1;
    cursor: pointer;
}

.selectorList__list ul {
    padding-left: 0;
    padding: 5px;
    margin: 0;
}

.selectorList__list li {
    cursor: pointer;
    font-size: 11px;
}
.selectorList__list li:hover {
   text-decoration: underline;
   color: #fb2921;
 }

.blockAdd {
    position: relative;
    padding: 20px 0px;
    display: flex;
    gap: 10px;
    font-family: Roboto-Medium, serif;
    border: 2px solid #88a4bd;
    border-radius: 10px;
    margin-bottom: 10px;
    justify-content: center;
}
.blockAdd__title {
    position: absolute;
    top: 1px;
    left: 10px;
}

.blockAdd .element {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.dataInput{

}

.dateInputWrap {
    border: 1px solid #999;
    padding: 5px;
    border-radius: 5px;
}


.formBlock {
    font-family: Roboto-Medium;
    line-height: 17px;
    font-size: 14px;
    width: 300px;
    border: 2px solid #88a4bd;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.formBlock .inputBlock {
    width: calc(100% - 10px);
    border-radius: 10px;
    border: 2px solid #88a4bd;
    padding: 5px;
    font-size: 15px;
}

.formBlock button {
    width: 100%;
    margin: 0;
    text-align: center;
    justify-content: center;
}

.elementHead {
    display: flex;
}

.infoBar {
    position: relative;
}
.btnOpen {
    border-radius: 15px;
    border: 2px solid #88a4bd;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.btnOpen:hover{
    background:#88a4bd ;
}
.list {
    position: absolute;
    left:100%;
    top: calc(-100% - 80px);
    z-index: 99999;
    background: #fff;
}
