@import "selectorList.css";
@import "simpleStyleTable.css";
@import "tableRoleCheck.css";

.ReactTable .rt-tr {
    min-height: 36px !important;
}

.ReactTable .rt-tbody {
    min-height: 80px;
}

.ReactTable .rt-thead .rt-tr {
    padding: 0 12px !important;
}

.ReactTable .rt-tbody .rt-tr-group {
    padding: 0 12px !important;
}

.ReactTable .rt-thead.-headerGroups .rt-th {
    font-family: Roboto-Bold, sans-serif;
    font-size: 14px;
}

.ReactTable .rt-table .-header {
    height: 36px !important;
}

.Drf {
    flex: 0 0 80px;
}

.NewSelect {
    font-family: Roboto-Bold, sans-serif;
    width: 40%;
}

.NewSelect > div {
    border: 2px solid #88a4bd;
    min-height: 40px;
}

.MR_24 {
    margin-right: 24px;
}

.wrap_select {
    width: 80%;
}

.wrap_select.dates {
    display: flex;
    justify-content: space-between;
}

.ReactModal__Overlay {
    overflow: auto;
    z-index: 5;
}

.ReactModal__Content {
    position: absolute !important;
    max-height: none !important;
    top: 100px !important;
    transform: translate(-50%, 0) !important;
    overflow: visible !important;
    margin-bottom: 100px;
}

.SingleDatePicker {
    width: 48% !important;
}

.DateInput__small {
    width: 92% !important;
}

.w100 {
    width: 100%;
}

.border > div {
    border-color: #999999;
}

.async_wrapper {
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
}

.async_wrapper button {
    margin: 5px;
}

.mailing_date_wrapper {
    width: 100%;
}

.checkboxSearchArhive {
    margin-left: 15px;
}

.table-hint {
    overflow: initial !important;
}

.table-hint__container {
    position: relative;
}

.table-hint__label {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-hint__hint {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    width: max-content;
    max-width: 250px;
    padding: 7px 10px;
    border: 1px solid #88a4bd;
    border-radius: 5px;
    white-space: break-spaces;
    word-wrap: break-word;
    background-color: #eef2f5;
}

.table-hint__label:hover + .table-hint__hint {
    display: block;
}

.btnControl {
    display: flex;
}

.confirmRuleBlock {
    border: 2px solid #88a4bd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    padding: 10px;
}

.wpapperMenu {
    position: relative;
}

.subMenuBlock {
    display: flex;
    position: absolute;
    right: 10px;
    top: 32px;
    width: 140px;
    border: 2px solid #fff;
    flex-direction: column;
    background: #234a97;
    box-shadow: 1px 1px 5px #000;
    border-radius: 13px;
    padding: 5px;
    gap: 0px;
    align-items: center;
    z-index: 99;
}

.controlBlock {
    display: flex;
    font-family: Roboto-Medium, serif;
    line-height: 17px;
    font-size: 14px;
    gap: 10px;
    border: 1px solid #999;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.controlBlock .dateInputWrap {
    padding: 6px;
}

.selectWrap {
    border: 1px solid #999;
    padding: 2px;
    border-radius: 5px;
}

.multiSelectCustomWrapper {
    position: relative;
    height: 28px;
    width: 200px;
    padding: 1px;
    color: #000;
    font-family: Roboto-Regular, serif;
    font-size: 14px;
}

.selectInput {
    border: 1px solid;
    padding: 5px;
    border-radius: 5px;
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.multiSelectCustom {
    cursor: pointer;
    font-family: Roboto-Regular, serif;
    font-size: 14px;
}

.multiSelectCustom:hover {
    background: #000;
    color: #fff;
}

.isSelectElement {
    background: #000;
    color: #fff;
}

.multiSelectCustomList {
    position: absolute;
    top: 30px;
    left: 0;
    width: calc(100% - 10px);
    display: flex;
    flex-direction: column;
    background: #fff;
    font-size: 10px;
    border-radius: 7px;
    border: 1px solid;
    box-shadow: 1px 1px 6px #000;
    grid-gap: 5px;
    gap: 1px;
    padding: 5px;
    z-index: 99;
}

.inputSearchBlock {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;

}

.inputSearchBlock input {
    font-family: Roboto-Regular, serif;
    font-size: 14px;
    width: calc(100% - 50px);
}

.closeButton {
    width: 20px;
    height: 20px;
    border: 2px solid #181818;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #000;
    font-size: 12px;
}

.searchSelector {
    height: 32px;
}

.searchSelector .selectInput {
    height: 30px;
    border-color: #99999a;
    display: flex;
    align-content: center;
    align-items: center;
    padding: 0 6px;
}

.controlEmployerReport {
    display: flex;
    align-items: flex-end;
}

.selectorProjects {
    width: 300px
}


.auto-search-container {
    border: 1px solid #999999;
    height: 34px;
    width: 300px;
    border-radius: 4px;
    position: relative;
}

.auto-search-inputBlock {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 34px;
}

.auto-search-input {
    border: 1px solid #999999;
    font-size: 14px;
    width: 190px;
    height: 16px;
}

.auto-search-btn {
    border: 1px solid #999999;
    font-size: 14px;
    width: 80px;
    height: 20px;
}

.auto-search-results {
    z-index: 99;
    position: absolute;
    top: calc(-100% - -60px);
    left: 0;
    width: 100%;
    min-height: 30px;
    background: #fff;
    box-shadow: 1px 1px 5px #000;
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-height: 300px;
    overflow-y: auto;
}

.project-list-item {
    width: 100%;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-list-item:hover {
    background: #000;
    color: #fff;
}

.resultDepartment {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.searchSelector .selectInput {
    width: 100%;
}

.mb10{
    margin-bottom: 10px;
}
