.checkTables {
    display: flex;
    gap: 10px
}

.checkTables .checkT {
    /*border-right: 2px solid #000;*/
    width: 100%;
}
.checkTables .checkT:last-child {
    border-right: none;
}
