.selectorList {
    position: relative;
    width: 200px;
    z-index: 999;
    border: 1px solid #999;
    padding: 5px;
    border-radius: 5px;
    font-family: Roboto-Medium;
    /*height: 20px;*/
}
.selectorList .inputBlock {
    width: 100%;
    max-width: calc(100% - 15px);
}
.selectorList__list {
    position: absolute;
    width: 100%;
    max-height: 200px;
    top: 40px;
    left: 0;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    overflow-y: auto;
    border: 2px solid #88a4bd;
}

.closeList {
    position: absolute;
    font-size: 10px;
    right: 0;
    top: 39px;
    z-index: 1;
    cursor: pointer;
}

.selectorList__list ul {
    padding-left: 0;
    padding: 5px;
    margin: 0;
}

.selectorList__list li {
    cursor: pointer;
    font-size: 11px;
}
.selectorList__list li:hover {
   text-decoration: underline;
   color: #fb2921;
 }

.blockAdd {
    position: relative;
    padding: 20px 0px;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    font-family: Roboto-Medium, serif;
    border: 2px solid #88a4bd;
    border-radius: 10px;
    margin-bottom: 10px;
    justify-content: center;
}
.blockAdd__title {
    position: absolute;
    top: 1px;
    left: 10px;
}

.blockAdd .element {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.dataInput{

}

.dateInputWrap {
    border: 1px solid #999;
    padding: 5px;
    border-radius: 5px;
}


.formBlock {
    font-family: Roboto-Medium;
    line-height: 17px;
    font-size: 14px;
    width: 300px;
    border: 2px solid #88a4bd;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    flex-direction: column;
}
.formBlock .inputBlock {
    width: calc(100% - 10px);
    border-radius: 10px;
    border: 2px solid #88a4bd;
    padding: 5px;
    font-size: 15px;
}

.formBlock button {
    width: 100%;
    margin: 0;
    text-align: center;
    justify-content: center;
}

.elementHead {
    display: flex;
}

.infoBar {
    position: relative;
}
.btnOpen {
    border-radius: 15px;
    border: 2px solid #88a4bd;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.btnOpen:hover{
    background:#88a4bd ;
}
.list {
    position: absolute;
    left:100%;
    top: calc(-100% - 80px);
    z-index: 99999;
    background: #fff;
}

table.minimalistBlack {
    border: 3px solid #000000;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}
table.minimalistBlack td, table.minimalistBlack th {
    border: 1px solid #000000;
    padding: 5px 4px;
}
table.minimalistBlack tbody td {
    font-size: 13px;
}
table.minimalistBlack thead {
    background: #CFCFCF;
    background: linear-gradient(to bottom, #dbdbdb 0%, #d3d3d3 66%, #CFCFCF 100%);
    border-bottom: 3px solid #000000;
}
table.minimalistBlack thead th {
    font-size: 15px;
    font-weight: bold;
    color: #000000;
    text-align: left;
}
table.minimalistBlack tfoot {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    border-top: 3px solid #000000;
}
table.minimalistBlack tfoot td {
    font-size: 14px;
}

.checkTables {
    display: flex;
    grid-gap: 10px;
    gap: 10px
}

.checkTables .checkT {
    /*border-right: 2px solid #000;*/
    width: 100%;
}
.checkTables .checkT:last-child {
    border-right: none;
}

.ReactTable .rt-tr {
    min-height: 36px !important;
}

.ReactTable .rt-tbody {
    min-height: 80px;
}

.ReactTable .rt-thead .rt-tr {
    padding: 0 12px !important;
}

.ReactTable .rt-tbody .rt-tr-group {
    padding: 0 12px !important;
}

.ReactTable .rt-thead.-headerGroups .rt-th {
    font-family: Roboto-Bold, sans-serif;
    font-size: 14px;
}

.ReactTable .rt-table .-header {
    height: 36px !important;
}

.Drf {
    flex: 0 0 80px;
}

.NewSelect {
    font-family: Roboto-Bold, sans-serif;
    width: 40%;
}

.NewSelect > div {
    border: 2px solid #88a4bd;
    min-height: 40px;
}

.MR_24 {
    margin-right: 24px;
}

.wrap_select {
    width: 80%;
}

.wrap_select.dates {
    display: flex;
    justify-content: space-between;
}

.ReactModal__Overlay {
    overflow: auto;
    z-index: 5;
}

.ReactModal__Content {
    position: absolute !important;
    max-height: none !important;
    top: 100px !important;
    transform: translate(-50%, 0) !important;
    overflow: visible !important;
    margin-bottom: 100px;
}

.SingleDatePicker {
    width: 48% !important;
}

.DateInput__small {
    width: 92% !important;
}

.w100 {
    width: 100%;
}

.border > div {
    border-color: #999999;
}

.async_wrapper {
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
}

.async_wrapper button {
    margin: 5px;
}

.mailing_date_wrapper {
    width: 100%;
}

.checkboxSearchArhive {
    margin-left: 15px;
}

.table-hint {
    overflow: initial !important;
}

.table-hint__container {
    position: relative;
}

.table-hint__label {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-hint__hint {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    width: -webkit-max-content;
    width: max-content;
    max-width: 250px;
    padding: 7px 10px;
    border: 1px solid #88a4bd;
    border-radius: 5px;
    white-space: break-spaces;
    word-wrap: break-word;
    background-color: #eef2f5;
}

.table-hint__label:hover + .table-hint__hint {
    display: block;
}

.btnControl {
    display: flex;
}

.confirmRuleBlock {
    border: 2px solid #88a4bd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 10px;
    padding: 10px;
}

.wpapperMenu {
    position: relative;
}

.subMenuBlock {
    display: flex;
    position: absolute;
    right: 10px;
    top: 32px;
    width: 140px;
    border: 2px solid #fff;
    flex-direction: column;
    background: #234a97;
    box-shadow: 1px 1px 5px #000;
    border-radius: 13px;
    padding: 5px;
    grid-gap: 0px;
    gap: 0px;
    align-items: center;
    z-index: 99;
}

.controlBlock {
    display: flex;
    font-family: Roboto-Medium, serif;
    line-height: 17px;
    font-size: 14px;
    grid-gap: 10px;
    gap: 10px;
    border: 1px solid #999;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.controlBlock .dateInputWrap {
    padding: 6px;
}

.selectWrap {
    border: 1px solid #999;
    padding: 2px;
    border-radius: 5px;
}

.multiSelectCustomWrapper {
    position: relative;
    height: 28px;
    width: 200px;
    padding: 1px;
    color: #000;
    font-family: Roboto-Regular, serif;
    font-size: 14px;
}

.selectInput {
    border: 1px solid;
    padding: 5px;
    border-radius: 5px;
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.multiSelectCustom {
    cursor: pointer;
    font-family: Roboto-Regular, serif;
    font-size: 14px;
}

.multiSelectCustom:hover {
    background: #000;
    color: #fff;
}

.isSelectElement {
    background: #000;
    color: #fff;
}

.multiSelectCustomList {
    position: absolute;
    top: 30px;
    left: 0;
    width: calc(100% - 10px);
    display: flex;
    flex-direction: column;
    background: #fff;
    font-size: 10px;
    border-radius: 7px;
    border: 1px solid;
    box-shadow: 1px 1px 6px #000;
    grid-gap: 5px;
    grid-gap: 1px;
    gap: 1px;
    padding: 5px;
    z-index: 99;
}

.inputSearchBlock {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    justify-content: space-between;

}

.inputSearchBlock input {
    font-family: Roboto-Regular, serif;
    font-size: 14px;
    width: calc(100% - 50px);
}

.closeButton {
    width: 20px;
    height: 20px;
    border: 2px solid #181818;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #000;
    font-size: 12px;
}

.searchSelector {
    height: 32px;
}

.searchSelector .selectInput {
    height: 30px;
    border-color: #99999a;
    display: flex;
    align-content: center;
    align-items: center;
    padding: 0 6px;
}

.controlEmployerReport {
    display: flex;
    align-items: flex-end;
}

.selectorProjects {
    width: 300px
}


.auto-search-container {
    border: 1px solid #999999;
    height: 34px;
    width: 300px;
    border-radius: 4px;
    position: relative;
}

.auto-search-inputBlock {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 34px;
}

.auto-search-input {
    border: 1px solid #999999;
    font-size: 14px;
    width: 190px;
    height: 16px;
}

.auto-search-btn {
    border: 1px solid #999999;
    font-size: 14px;
    width: 80px;
    height: 20px;
}

.auto-search-results {
    z-index: 99;
    position: absolute;
    top: calc(-100% - -60px);
    left: 0;
    width: 100%;
    min-height: 30px;
    background: #fff;
    box-shadow: 1px 1px 5px #000;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    max-height: 300px;
    overflow-y: auto;
}

.project-list-item {
    width: 100%;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-list-item:hover {
    background: #000;
    color: #fff;
}

.resultDepartment {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.searchSelector .selectInput {
    width: 100%;
}

.mb10{
    margin-bottom: 10px;
}

.ReactTable {
    border: none;
}

.ReactTable .rt-table {
    /* this is done to make dropdown menu visible */
    overflow: visible;

}

.ReactTable .rt-table .-header {
    height: 60px;
    background-color: #fdfdfe;
    font-family: Roboto-Bold, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #999999;
    box-shadow: none;
}

.ReactTable .rt-thead .rt-tr {
    align-items: center;
    text-align: left;
    padding: 0 25px;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
    border-right: none;
    
}
.ReactTable .rt-tbody .rt-td {
    border-right: none;
    height: 40px !important;
}

.ReactTable .rt-tbody {
    background-color: #fcfcfd;
    font-family: Roboto-Regular, sans-serif;

    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #394a58;

    /* this is done to make dropdown menu visible */
    overflow: visible;
}

.ReactTable .rt-tbody .rt-tr-group {
    justify-content: stretch;
    padding: 0 25px;
}

.ReactTable .rt-tr {
    align-items: stretch;
    min-height: 60px;
}

.ReactTable .rt-thead .rt-th {
    outline: none;
}


